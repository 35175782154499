<template>
	<div class="uk-margin" :style="{'padding-left': `${espaciado}px`}">
		<label class="uk-form-label" :for="`fbldd${id}`">Si la variable «<em>{{ variable }}</em>» está vacía:</label>
		<div class="uk-form-controls">
			<select class="uk-select uk-form-small" :id="`fbldd${id}`" :value="modelValue" @input="alCambiar">
				<option value="">--</option>
				<option :value="1">Omitir cálculo de fiabilidad</option>
				<option :value="0.95">Asumir fiabilidad muy alta</option>
				<option :value="0.9">Asumir fiabilidad alta</option>
				<option :value="0.85">Asumir fiabilidad media-alta</option>
				<option :value="0.8">Asumir fiabilidad media</option>
			</select>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'fiabilidadSiVacio',
	props: ['variable', 'id', 'espaciado', 'modelValue'],
	emits: ['update:modelValue'],
	methods: {
		alCambiar: function(e: any) {
			if (!e || !e.target || !e.target.value) {
				this.$emit('update:modelValue', null)
				return
			}

			let v = e.target.value
			if (typeof v === 'string') {
				v = Number(v)
			}

			if (typeof v !== 'number' || isNaN(v) || !isFinite(v)) {
				this.$emit('update:modelValue', null)
				return
			}

			this.$emit('update:modelValue', v)
		}
	}
});
</script>
