
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'fiabilidadSiVacio',
	props: ['variable', 'id', 'espaciado', 'modelValue'],
	emits: ['update:modelValue'],
	methods: {
		alCambiar: function(e: any) {
			if (!e || !e.target || !e.target.value) {
				this.$emit('update:modelValue', null)
				return
			}

			let v = e.target.value
			if (typeof v === 'string') {
				v = Number(v)
			}

			if (typeof v !== 'number' || isNaN(v) || !isFinite(v)) {
				this.$emit('update:modelValue', null)
				return
			}

			this.$emit('update:modelValue', v)
		}
	}
});
