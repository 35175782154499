
<template>

	<div class="contenedor" style="padding: 10px 16px; margin-bottom: 40px">
		<h3>Verificación de entes</h3>

		<hr>

		<div class="uk-margin">
			<label class="uk-form-label" for="grupo-entes-entes">Grupo de entes:</label>
			<div class="uk-form-controls">
				<select class="uk-select" id="grupo-entes-entes" v-model="grupoDeEntes">
					<option v-for="g in estadoGrupoDeEntes.listado" :value="g.identificador" :key="`vrfccn-ge-${g.identificador}`">
						{{ g.descripcion }} ({{ g.identificador }})
					</option>
				</select>
			</div>
		</div>
	</div>

	<form @submit.prevent="guardar">

		<h2>Medios de autenticación</h2>

		<div class="contenedor" style="padding: 10px 16px">

			<div class="uk-margin">
				<div class="uk-form-label">
					<h4>Seleccione los medios a través de los cuales se enviará a los entes el código de autenticación cuando sea requerido.</h4>
				</div>
				<div class="uk-form-controls">
					<label><input class="uk-checkbox" type="checkbox" v-model="medios.correoElectronico"> Correo electrónico</label>
					<br>
					<label><input class="uk-checkbox" type="checkbox" v-model="medios.mensajeSMS"> Mensaje SMS</label>
				</div>
			</div>

			<p></p>

			<div class="uk-margin" v-show="medios.correoElectronico && medios.mensajeSMS">
				<div class="uk-form-label">Seleccione la prioridad que tendrá cada medio:</div>
				<div class="uk-form-controls">
					<label>
						<input
							class="uk-radio"
							type="radio"
							name="preferencia-de-medio"
							value="mensaje-sms|correo-electronico"
							v-model="preferenciaDeMedio"
						>
						Usar mensajes SMS siempre que sea posible. Se usará correo electrónico cuando el ente no tenga configurado un número telefónico o cuando ocurra un error.
					</label><br>
					<label>
						<input
							class="uk-radio"
							type="radio"
							name="preferencia-de-medio"
							value="correo-electronico|mensaje-sms"
							v-model="preferenciaDeMedio"
						>
						Usar correo electrónico siempre que sea posible. Se usará un mensaje SMS cuando el ente no tenga configurada una dirección de correo electrónico o cuando ocurra un error.
					</label><br>
					<label>
						<input
							class="uk-radio"
							type="radio"
							name="preferencia-de-medio"
							value="todo"
							v-model="preferenciaDeMedio"
						>
						Usar tanto correo electrónico como mensaje SMS a la vez. Se enviará el código por los dos medios cuando el ente tenga configurados los dos medios.
					</label>
				</div>
			</div>

			<!--

			aquí agregar opción para qué hacer las primeras veces.-->

		</div>

		<h2>Autoaprendizaje de medios de autenticación</h2>

		<div class="contenedor" style="padding: 10px 16px">

			<p>Puede hacer que el sistema aprenda automáticamente el número telefónico y correo electrónico asociados al ente a partir de los datos enviados en las variables de los eventos.</p>

			<div class="uk-margin">
				<div class="uk-form-label">
					<h4>Seleccione cómo quiere que se aprendan los medios de autenticación</h4>
				</div>
				<div class="uk-form-controls">
					<label><input class="uk-radio" type="radio" name="autoaprendizaje-medios" value="adquisicion.receptorDeFacturacion" v-model="autoaprendizaje"> Aprender los medios de autenticación desde las variables de facturación de una adquisición (Datos en la variable <i>adquisicion.receptorDeFacturacion</i> del servicio REST)</label>
					<br>
					<label><input class="uk-radio" type="radio" name="autoaprendizaje-medios" value="adquisicion.destinatarioDeEnvio" v-model="autoaprendizaje"> Aprender los medios de autenticación desde las variables de envío de una adquisición de un producto (Datos en la variable <i>adquisicion.destinatarioDeEnvio</i> del servicio REST)</label>
					<br>
					<label><input class="uk-radio" type="radio" name="autoaprendizaje-medios" value="" v-model="autoaprendizaje"> No usar autoaprendizaje</label>
				</div>
			</div>

			<p></p>


		</div>

		<br>
		<br>

		<button class="uk-button uk-button-primary" type="submit" :disabled="reglas.estado.guardando">Guardar</button>

	</form>

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import fiabilidadSiVacio from '@/components/fiabilidadSiVacio.vue'
import { reglas } from '@/fuentes'
import { estadoGrupoDeEntes, listarGruposDeEntesUnaVez } from '@/gruposDeEntes'

export default defineComponent({
  name: 'VerificacionDeEntes',
  data: () => ({
		reglas: reglas,
		estadoGrupoDeEntes: estadoGrupoDeEntes,
		grupoDeEntes: '',
		medios: {
			correoElectronico: false,
			mensajeSMS: false
		},
		preferenciaDeMedio: 'mensaje-sms|correo-electronico',
		autoaprendizaje: ''
	}),
	components: {
		fiabilidadSiVacio
	},
	mounted() {
		listarGruposDeEntesUnaVez().then(() => {
			if (this.estadoGrupoDeEntes.listado.length === 1) {
				this.grupoDeEntes = this.estadoGrupoDeEntes.listado[0].identificador
			}
		})
	},
	watch: {
		'grupoDeEntes': function() {
			if (!this.grupoDeEntes) {
				this.limpiar()
				return
			}
		}
	},
	methods: {
		guardar: function() {
			if (this.reglas.estado.guardando) {
				return
			}
		},
		limpiar: function() {
			//
		}
	}
});
</script>
