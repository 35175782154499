
import { defineComponent } from 'vue';
import fiabilidadSiVacio from '@/components/fiabilidadSiVacio.vue'
import { reglas } from '@/fuentes'
import { estadoGrupoDeEntes, listarGruposDeEntesUnaVez } from '@/gruposDeEntes'

export default defineComponent({
  name: 'VerificacionDeEntes',
  data: () => ({
		reglas: reglas,
		estadoGrupoDeEntes: estadoGrupoDeEntes,
		grupoDeEntes: '',
		medios: {
			correoElectronico: false,
			mensajeSMS: false
		},
		preferenciaDeMedio: 'mensaje-sms|correo-electronico',
		autoaprendizaje: ''
	}),
	components: {
		fiabilidadSiVacio
	},
	mounted() {
		listarGruposDeEntesUnaVez().then(() => {
			if (this.estadoGrupoDeEntes.listado.length === 1) {
				this.grupoDeEntes = this.estadoGrupoDeEntes.listado[0].identificador
			}
		})
	},
	watch: {
		'grupoDeEntes': function() {
			if (!this.grupoDeEntes) {
				this.limpiar()
				return
			}
		}
	},
	methods: {
		guardar: function() {
			if (this.reglas.estado.guardando) {
				return
			}
		},
		limpiar: function() {
			//
		}
	}
});
